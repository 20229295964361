/*
Template Name: Tailwick - Admin & Dashboard Template
Author: Themesdesign
Version: 1.1.0
Website: https://themesdesign.in/
Contact: Themesdesign@gmail.com
File: tailwind scss File
*/

@import "fonts/fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "plugins/gridjs";
@import "plugins/select";
@import "plugins/multi-select";

#chat-wrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    --tw-text-opacity: 1;
    // color: white;
    color: black;
  }
  h2 {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.05rem;
    color: #000000;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    letter-spacing: 0.03rem;
    overflow-wrap: break-word;
    strong {
      font-weight: 600;
    }
  }
  pre {
    background-color: #0000000f !important;
    border-radius: 0.375rem !important;
    padding: 15px;
    color: #000000 !important;
    font-size: 0.875em !important;
    font-weight: 400 !important;
    line-height: 1.7142857 !important;
    overflow: auto;
    code {
      color: #000000 !important;
    }
  }
  code {
    letter-spacing: normal;
    font-size: 14px;
  }
  p {
    margin-bottom: 0.5rem;
    font-size: 15px;
    letter-spacing: 0.03rem;
    font-weight: 300;
  }
  thead tr th {
    text-align: left;
  }
  ul {
    padding-left: 1.625em;
    list-style-type: disc;
    margin-bottom: 1.25em;
    li {
      padding-left: 0.375em;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      font-size: 15px;
      letter-spacing: 0.03rem;
      line-height: 28px;
      font-weight: 200;
      strong {
        font-weight: 300;
      }
    }
  }
  ol {
    list-style-type: decimal;
    padding-left: 1.625em;
    margin-bottom: 1.25em;
    li {
      padding-left: 0.375em;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      font-size: 15px;
      letter-spacing: 0.03rem;
      line-height: 28px;
      font-weight: 200;
      strong {
        font-weight: 300;
      }
    }
  }
  hr {
    border-color: hsl(0deg 0% 100% / 25%);
    border-top-width: 1px;
    margin-bottom: 3em;
    margin-top: 3em;
  }
}
.simplebar-wrapper {
  overflow: auto !important;
}
.custom-ver-drp {
  position: absolute;
  inset: 20px auto auto 0px !important;
  margin: 0px;
  transform: translate(-14px, 20px) !important;
  width: 150px;
  text-align: left;
}

.group\/menu-link:hover,
.animate {
  animation-iteration-count: 2;
  stroke-dasharray: 10;
}
@layer utilities {
  .w-fill-available {
    width: -webkit-fill-available;
  }
}
.w-webkit-fill {
  width: -webkit-fill-available;
}

/* Custom css */
.form-input {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  font-size: 13px !important;
}
.font-public {
  font-family: Lato, Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.03rem;
}
.bg-body-bg {
  background-color: #f7f7f8 !important;
}
@media (min-width: 768px) {
  .group[data-sidebar-size="sm"]
    .group-data-\[sidebar-size\=sm\]\:ltr\:md\:left-vertical-menu-sm:where(
      [dir="ltr"],
      [dir="ltr"] *
    ) {
    left: 4.375rem !important;
  }
}

@media (min-width: 768px) {
  .ltr\:md\:left-vertical-menu:where([dir="ltr"], [dir="ltr"] *) {
    left: 200px !important;
  }
}

.w-vertical-menu {
  width: 200px;
}

@media (min-width: 768px) {
  .group[data-sidebar-size="lg"]
    .group-data-\[sidebar-size\=lg\]\:ltr\:md\:ml-vertical-menu:where(
      [dir="ltr"],
      [dir="ltr"] *
    ) {
    margin-left: 200px !important;
  }
}

.font-public {
  font-family: "Lato", Helvetica, Arial, sans-serif !important;
}

/* Custom css */
// .css-1dyz3mf {
//   height: 24px;
// }
.container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
// ::-webkit-scrollbar {
//   width: 0; /* Remove scrollbar space */
//   background: transparent; /* Optional: just make scrollbar invisible */
// }
// .scroll-container::-webkit-scrollbar {
//   display: none;
// }

// .simplebar-content-wrapper
// ::-webkit-scrollbar {
//   width: 0; /* Remove scrollbar space */
//   background: transparent; /* Optional: just make scrollbar invisible */
// }
// .scroll-container::-webkit-scrollbar {
//   display: none;
// }
#scrollbar .simplebar-scrollbar {
  display: none !important;
}
.link-a a {
  color: rgb(60 130 246);
}
.custom-editor-css ul {
  list-style: disc !important;
  padding-left: 25px !important;
}
.custom-editor-css li {
  margin-bottom: 5px !important;
}
.custom-editor-css ol {
  list-style-type: decimal !important;
  padding-left: 25px !important;
  margin-bottom: 5px !important;
}
.with-arrow::after {
  content: " ";
  position: absolute;
  right: 3%;
  top: -15px;
  border: 15px solid transparent;
  border-top: none;
  border-bottom: 15px solid #1a1c21;
}

.blink {
  animation: blinker 1s cubic-bezier(0.5, 0.5, 0.9, 0.2) infinite alternate;
  margin-right: 5px;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Custom css for from */
.react-datepicker-wrapper {
  display: block !important;
}
.css-13cymwt-control {
  border-color: inherit !important;
}

/* Calendar custom styling */
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 7px !important;
  border: none !important;
  display: inline-grid !important;
}
.flatpickr-months .flatpickr-month .flatpickr-current-month {
  justify-content: center !important;
}
span.flatpickr-weekday {
  color: rgb(255 255 255) !important;
}
.flatpickr-current-month .numInputWrapper {
  width: 7ch !important;
}
.numInputWrapper:hover {
  padding: 2px !important;
}
.numInputWrapper {
  padding: 2px !important;
}
.flatpickr-months
  .flatpickr-month
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover {
  background-color: #0000000f !important;
  padding: 2px !important;
  margin-right: 2px !important;
  color: rgb(255 255 255) !important;
}
.flatpickr-months
  .flatpickr-month
  .flatpickr-current-month
  .flatpickr-monthDropdown-months {
  padding: 2px !important;
  margin-right: 2px !important;
  width: 100px !important;
}
span.flatpickr-day.selected {
  border-color: #000000 !important;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none !important;
}

#sidebar-scr .simplebar-wrapper {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
